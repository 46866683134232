import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CheckCircle from "@material-ui/icons/CheckCircle";
import axios from "axios";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { ContentContainer } from "../../components/layout/components/contentContainer";
import ContentHeader from "../../components/layout/components/contentHeader";
import { formatter } from "../../functions";
import { TuimButton } from "../layout/Button";
import Loading from "../utils/Loading";

class ListKits extends Component {
  constructor(props) {
    super(props);

    const { classes } = props;

    this.state = {
      classes,
      rowsPerPage: 25,
      count: 0,
      page: 0,
      data: [],
      searchTerm: "",
      loading: false,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
  }

  componentDidMount = async () => {
    await this.getKits();
  };

  getKits = async (pageGo = 0) => {
    const _this = this;
    const token = localStorage.getItem("token");
    const { rowsPerPage, searchTerm } = this.state;

    _this.setState({ loading: true });

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/listAllKits?limit=${rowsPerPage}&page=${pageGo}&searchTerm=${searchTerm}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        const {
          data: { data = [], count },
        } = response;
        _this.setState({
          data,
          loading: false,
          count,
          page: pageGo,
        });
        this.setState({ searchTerm: "" });
      });
  };

  handleChangePage = (event, pageToGo) => {
    const { page: currentPage } = this.state;
    let nextPage = pageToGo;

    if (pageToGo > currentPage && pageToGo === 1) nextPage = 2;
    if (pageToGo < currentPage && pageToGo === 1) nextPage = 0;

    this.setState({ page: nextPage });
    this.getKits(nextPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: event.target.value }, () =>
      this.getKits()
    );
  };

  onSearchChange = (e) => {
    this.setState({
      searchTerm: e.target.value,
    });
  };

  render() {
    const { classes, data, searchTerm, loading, rowsPerPage, page, count } =
      this.state;

    return (
      <>
        <Loading loading={loading} />

        <ContentHeader
          title="Kits"
          buttons={[
            {
              text: "Novo",
              secondary: true,
              onClick: () => this.props.history.push(`/app/kit/`),
            },
          ]}
        />

        <ContentContainer>
          <div
            style={{ display: "flex", flexDirection: "column", padding: 20 }}
          >
            <div style={{ display: "flex", marginTop: 0, gap: 16 }}>
              <TextField
                id="searchTerm"
                label="Nome do Kit"
                type="text"
                placeholder="Sala de Jantar, Dormitório Casal..."
                fullWidth
                value={searchTerm}
                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TuimButton
                text="Filtrar"
                onClick={() => {
                  this.setState({ page: 0 }, () => this.getKits());
                }}
              />
            </div>
          </div>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
          <Table style={{ maxWidth: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Preço</TableCell>
                <TableCell align="center">Data de Cadastro</TableCell>
                <TableCell>Ativo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow
                  key={row._id}
                  hover
                  onClick={() => window.open(`/app/kit/${row._id}`, "_blank")}
                  className={classes.row}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.description}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {formatter.currency(row.price)}
                  </TableCell>
                  <TableCell align="center">
                    {formatter.date(row.createdAt, "short")}
                  </TableCell>
                  <TableCell align="center">
                    {row.active ? <CheckCircle /> : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Página anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima página",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </ContentContainer>
      </>
    );
  }
}

ListKits.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 2,
    overflowX: "auto",
  },
  table: {
    minWidth: 700,
  },
  row: {
    cursor: "pointer",
  },
  fab: {
    margin: 0,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade("#ececec", 0.35),
    "&:hover": {
      backgroundColor: fade("#ececec", 0.8),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    marginLeft: theme.spacing.unit * 1,
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 5,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
});

export default withStyles(styles)(ListKits);
