import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import axios from "axios";
import "moment/locale/pt-br";
import React, { useEffect, useReducer, useState } from "react";
import { BsPostcard } from "react-icons/bs";
import { FaLongArrowAltDown, FaLongArrowAltUp } from "react-icons/fa";
import { HiBadgeCheck, HiOutlineViewList } from "react-icons/hi";
import { ImBlocked } from "react-icons/im";
import { LiaComment } from "react-icons/lia";
import { PiClockCountdown } from "react-icons/pi";
import Moment from "react-moment";
import { formatter, pluralize, uid } from "../../functions";
import { Colors } from "../../styles/Colors";
import { ContentContainer } from "../layout/components/contentContainer";
import ContentHeader from "../layout/components/contentHeader";
import { Rows } from "../layout/components/rows";
import { Text } from "../layout/components/text";
import Loading from "../utils/Loading";
import { handleDownloadXLS } from "../utils/downloadXLS";

const token = localStorage.getItem("token");
const xlsFileName = `Carrinhos_Tuim_${new Date()
  .toISOString()
  .split("T")[0]
  .split("-")
  .reverse()
  .join("_")}`;

const viewModes = [
  {
    name: "list",
    icon: HiOutlineViewList,
  },
  {
    name: "card",
    icon: BsPostcard,
  },
];

export const ListCart = () => {
  const [data, setData] = useState({ carts: [], count: 0 });
  const [viewMode, setViewMode] = useState("card");
  const [loadingContent, setLoadingContent] = useState(false);
  const [filter, setFilter] = useState(["known", "unknown"]);

  const reducer = (prev, next) => ({ ...prev, ...next });
  const [pagination, setPagination] = useReducer(reducer, { page: 0 });

  const handleChangeFilter = ({ target: { name } }) => {
    let newFilter = [...new Set([...filter, name])];

    if (filter.includes(name) && filter.length > 1) {
      newFilter = filter.filter((item) => item !== name);
    }

    setFilter(newFilter);
    setPagination({ page: 0 });
  };

  const handleChangePage = (page) => {
    setPagination({ page: page * 1 });
  };

  const handleChangeRowsPerPage = ({ target }) => {
    const rowsPerPage = target.value * 1;
    localStorage.setItem("tuimAdmin.cartsRowsPerPage", pagination.rowsPerPage);
    setPagination({ rowsPerPage });
  };

  const getCarts = async ({ listFilter } = "") => {
    const skip = pagination.rowsPerPage * pagination.page;
    if (!listFilter) listFilter = { key: "createdAt", sort: true };

    setLoadingContent(true);
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/v2/getcarts?limit=${
          pagination.rowsPerPage
        }&skip=${skip}&filters=${filter.join(",")}&filterByColumnKey=${
          listFilter.key
        }&filterByColumnSort=${listFilter.sort ? -1 : 1}`,
        {
          headers: { Authorization: "bearer " + token },
        }
      )
      .then((response) => {
        setData({
          carts: response.data.content,
          count: response.data.count,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingContent(false));
  };

  useEffect(() => {
    const viewMode = localStorage.getItem("tuimAdmin.viewMode");
    const rowsPerPage = localStorage.getItem("tuimAdmin.cartsRowsPerPage");
    setViewMode(viewMode || "list");
    setPagination({ rowsPerPage: rowsPerPage * 1 || 10 });
  }, []);

  useEffect(() => {
    localStorage.setItem("tuimAdmin.viewMode", viewMode);
  }, [viewMode]);

  useEffect(() => {
    if (pagination.rowsPerPage && typeof pagination.page === "number") {
      getCarts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter]);

  return (
    <>
      <Loading loading={loadingContent} />
      <ContentHeader
        title="Carrinhos"
        buttons={[
          {
            text: "Métricas",
            secondary: true,
            onClick: () => window.open("/app/carts/product-metrics"),
          },
          {
            text: "Exportar",
            tertiary: true,
            onClick: async () => {
              setLoadingContent(true);
              await handleDownloadXLS({
                token,
                xlsFileName,
                url: "/v2/downloadCarts",
              });
              setLoadingContent(false);
            },
          },
        ]}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          gap: 16,
        }}
      >
        <FormGroup style={{ display: "flex", flexDirection: "row" }}>
          <FormControlLabel
            control={<Checkbox checked={filter.includes("known")} />}
            label={<Text>Identificados</Text>}
            name="known"
            onChange={handleChangeFilter}
          />
          <FormControlLabel
            control={<Checkbox checked={filter.includes("unknown")} />}
            label={<Text>Desconhecidos</Text>}
            name="unknown"
            onChange={handleChangeFilter}
          />
        </FormGroup>

        <Divider orientation="vertical" />
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component="div"
          count={data.count}
          rowsPerPage={pagination.rowsPerPage || 5}
          page={pagination.page}
          backIconButtonProps={{
            "aria-label": "Página anterior",
          }}
          nextIconButtonProps={{
            "aria-label": "Próxima página",
          }}
          labelDisplayedRows={({ from, to, count }) => (
            <Text>
              {from}-{to} de {count}
            </Text>
          )}
          size="small"
          labelRowsPerPage={<Text>Itens por página</Text>}
          onChangePage={(e, page) => handleChangePage(page)}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          SelectProps={{
            variant: "outlined",
          }}
        />
        <Divider orientation="vertical" />

        <Box
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Text style={{ marginRight: 12 }}>Exibição:</Text>
          {viewModes.map(({ name, icon: Icon }) => (
            <Button
              key={uid()}
              onClick={async () => {
                await getCarts({
                  listFilter: { key: "createdAt", sort: true },
                });
                setViewMode(name);
              }}
              style={{
                backgroundColor:
                  viewMode === name ? Colors.paleDarkGreen : "transparent",
              }}
            >
              <Icon
                size={24}
                color={
                  viewMode === name ? Colors.darkGreen : Colors.darkGreen + "77"
                }
              />
            </Button>
          ))}
        </Box>
      </Box>
      {data.carts.length > 0 && viewMode === "list" ? (
        <List carts={data.carts} rowAction={window.open} getCarts={getCarts} />
      ) : (
        <Card carts={data.carts} />
      )}
    </>
  );
};

const Card = ({ carts }) => (
  <Box style={{ display: "flex", gap: 24, flexWrap: "wrap" }}>
    {carts.map((cart, index) => {
      const cartIdCode = String(cart?._id).substring(18, 24).toUpperCase();
      const saleMade = Boolean(cart?.saleMade) || Boolean(cart?.contractId);
      const border = saleMade ? `2px solid ${Colors.lightGreen}` : "none";
      const repeatCustomer = cart?.id_user?.repeatCustomer;
      const productsQtd = Array.isArray(cart.products)
        ? cart.products.length
        : Object.keys(cart.products).length;
      const isBlocked = cart?.id_user?.blocked;

      return (
        <Link
          key={`${cart?._id}_${index}`}
          href={`/app/carts/${cart?._id}`}
          target="_blank"
          style={{
            textDecoration: "none",
            cursor: "pointer",
            display: "flex",
            flex: 1,
            minWidth: 300,
            maxWidth: 360,
            boxSizing: "border-box",
          }}
        >
          <ContentContainer
            style={{
              boxSizing: "border-box",
              flex: 1,
              border,
              position: "relative",
              padding: "30px 40px",
            }}
          >
            <Box>
              <Box style={{ display: "flex", gap: 4, alignItems: "center" }}>
                {isBlocked ? <ImBlocked color={Colors.red} size={16} /> : <></>}
                <Text
                  strong
                  style={{
                    color: cart?.id_user ? "#444" : "#aaa",
                    fontSize: 20,
                    lineHeight: "normal",
                  }}
                >
                  {cart?.id_user?.name || "Desconhecido"}
                </Text>
              </Box>

              <Box
                style={{
                  display: "flex",
                  gap: 4,
                  color: "#999",
                  alignItems: "center",
                  marginTop: 4,
                }}
              >
                <PiClockCountdown size={18} color="inherit" />
                <Text detail>Atualizado</Text>
                <Moment
                  fromNow
                  style={{
                    color: "inherit",
                    fontSize: 13,
                    lineHeight: "13px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {cart.updatedAt}
                </Moment>
              </Box>
            </Box>
            <Rows
              data={[
                {
                  label: "ID",
                  description: cartIdCode,
                },
                {
                  label: "Criado em",
                  description: formatter.date(cart?.createdAt, "short"),
                },
                {
                  label: "Periodo",
                  description: `${cart?.period} ${pluralize(
                    "mês",
                    cart?.period
                  )}`,
                },
                {
                  label: "Valor",
                  description: formatter.currency(
                    cart?.price?.priceWithDiscount ||
                      cart?.price?.priceWithoutDiscount ||
                      cart?.costs?.subscription
                  ),
                },
              ]}
            />
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                gap: 4,
                flex: 1,
              }}
            >
              <Text strong>{`Produtos (${productsQtd})`}</Text>
              <Box
                style={{
                  display: "flex",
                  gap: 8,
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                {(Array.isArray(cart.products)
                  ? cart.products
                  : Object.entries(cart.products)
                ).map((product) => (
                  <div
                    key={`list-cart-products-as-cart-${product?.[0]}`}
                    style={{
                      backgroundImage: `url(${product?.[1]?.urlImg})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      minWidth: 80,
                      width: 80,
                      height: 80,
                      borderRadius: 4,
                    }}
                  />
                ))}
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    background: "linear-gradient(to right, transparent, #fff)",
                    width: "40%",
                    height: "100%",
                  }}
                />
              </Box>
            </Box>
            <Box
              style={{
                position: "absolute",
                top: 16,
                right: 16,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 4,
              }}
            >
              {saleMade && <HiBadgeCheck color={Colors.lightGreen} size={22} />}
              {repeatCustomer && (
                <img
                  src="/icons/tuimIcon.png"
                  alt="Tuim-icon"
                  style={{ width: 20 }}
                />
              )}
              {Boolean(cart?.[1]?.notesCount) && (
                <LiaComment size={22} color={Colors.lightGreen} />
              )}
            </Box>
          </ContentContainer>
        </Link>
      );
    })}
  </Box>
);

const listColumns = [
  { label: "ID", field: "_id" },
  { label: "Usuário", field: "id_user.name" },
  { label: "Criado em", field: "createdAt" },
  { label: "Última atualização", field: "updatedAt" },
  { label: "Período", field: "period" },
  { label: "Produtos", field: "products" },
  { label: "Valor", field: "price.priceWithoutDiscount", width: 160 },
];

const List = ({ carts, rowAction, orderBy, getCarts }) => {
  const [filterByColumn, setFilterByColumn] = useState({
    key: "createdAt",
    sort: true,
  });

  const validateFilterByColumn = (item) => {
    if (item === filterByColumn.key)
      return { key: item, sort: !filterByColumn.sort };

    return { key: item, sort: true };
  };

  const applyColumnFilter = async (item) => {
    const newValue = validateFilterByColumn(item);
    setFilterByColumn(newValue);

    await getCarts({ listFilter: newValue });
  };

  return (
    <ContentContainer>
      <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Table id="table_carts" style={{ backgroundColor: "#fff" }}>
          <TableHead>
            <TableRow>
              {listColumns.map(
                ({ label, field, width = null }) => (
                  <TableCell key={label} style={{ ...(width && { width }) }}>
                    <Box
                      style={styles.tableTitle}
                      onClick={async () => await applyColumnFilter(field)}
                    >
                      {label}
                      {filterByColumn.key === field && filterByColumn.sort ? (
                        <FaLongArrowAltDown color="#999" size={12} />
                      ) : (
                        <FaLongArrowAltUp color="#999" size={12} />
                      )}
                    </Box>
                  </TableCell>
                ),
                this
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {carts?.map((cart) => {
              const userName = cart.id_user?.name;
              const price =
                cart?.price?.priceWithDiscount ||
                cart?.price?.priceWithoutDiscount ||
                cart?.costs?.subscription;
              const saleMade =
                Boolean(cart.saleMade) || Boolean(cart.contractId);
              const notesCount = Boolean(cart?.notesCount);
              const repeatCustomer = cart?.id_user?.repeatCustomer;
              const productsQtd = Array.isArray(cart.products)
                ? cart.products.length
                : Object.keys(cart.products).length;
              const isBlocked = cart?.id_user?.blocked;

              return (
                <TableRow
                  key={cart._id}
                  hover
                  style={{
                    position: "relative",
                    cursor: "pointer",
                    backgroundColor: saleMade ? "#e5eee0" : "initial",
                  }}
                  onClick={() => {
                    rowAction(`/app/carts/${cart._id}`);
                    window.scroll({ top: 0, behavior: "smooth" });
                  }}
                >
                  <TableCell align="left">
                    <Text>{cart.cartCode}</Text>
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ maxWidth: 200, whiteSpace: "nowrap" }}
                  >
                    <Box
                      style={{ display: "flex", gap: 4, alignItems: "center" }}
                    >
                      {isBlocked ? (
                        <ImBlocked color={Colors.red} size={16} />
                      ) : (
                        <></>
                      )}
                      <Text
                        style={{
                          color: userName ? "#444" : "#999",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                        }}
                      >
                        {userName || "Desconhecido"}
                      </Text>
                    </Box>
                  </TableCell>
                  <TableCell align="left">
                    <Text>{formatter.date(cart.createdAt, "short")}</Text>
                  </TableCell>
                  <TableCell align="left">
                    <Moment
                      fromNow
                      style={{ color: "#444", textTransform: "capitalize" }}
                    >
                      {cart.updatedAt}
                    </Moment>
                  </TableCell>
                  <TableCell align="left">
                    <Text>
                      {cart.period} {pluralize("mês", cart.period)}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text
                      style={{ color: productsQtd === 0 ? "#aaa" : "#444" }}
                    >
                      {productsQtd === 0 ? "Vazio" : String(productsQtd)}
                    </Text>
                  </TableCell>
                  <TableCell align="left">
                    <Text>{formatter.currency(price)}</Text>
                  </TableCell>
                  <Box style={styles.icons}>
                    {notesCount && (
                      <LiaComment size={22} color={Colors.lightGreen} />
                    )}
                    {repeatCustomer && (
                      <img
                        src="/icons/tuimIcon.png"
                        alt="Tuim-icon"
                        style={{ width: 15 }}
                      />
                    )}
                    {saleMade && (
                      <HiBadgeCheck size={20} color={Colors.lightGreen} />
                    )}
                  </Box>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </ContentContainer>
  );
};

const styles = {
  icons: {
    position: "absolute",
    width: 100,
    height: "100%",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    gap: 2,
    justifyContent: "flex-end",
    paddingRight: 6,
  },

  tableTitle: {
    display: "flex",
    alignItems: "center",
    gap: 3,
    cursor: "pointer",
    width: "fit-content",
  },
};
